import { Suspense, useContext, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Spin } from 'antd';

import Layout from './Components/Layout.js';
import CompanyInformation from 'pages/Hr/Layout/CompanyInformation.jsx';
import ViewJob from './pages/Hr/Jobs/ViewJob.js';
import RegistrationManagement from './pages/Hr/RegistrationManagement/RegistrationManagement.js';
import Login from './pages/Auth/Login.js';
import OTPForm from './pages/Auth/OTPForm.js';
import ForgotPassword from './pages/Auth/ForgotPassword.js';
import VerifyOTP from './pages/Auth/VerifyOTP.js';
import ConfirmPassword from './pages/Auth/ConfirmPassword.js';
import ErrorPage from './pages/Auth/ErrorPage.js';
import EmployerRegister from './pages/Hr/EmployerRegister/EmployerRegister.js';
import EditJob from './pages/Hr/Jobs/EditJob.js';
import Communication from './pages/Hr/Communication/Communication.js';
import PreScreen from './pages/Hr/PreScreen.js/PreScreen.js';
import routes, {
  Candidates,
  CandidatesInfoPage,
  JobDetails,
  Jobs,
  Settings,
  CreateQuestionnairePage,
  ConfigureQuestionnairePage,
  QuestionBanks,
  ViewQuestionnairePage,
  ViewQuestionBankPage,
  Interviewer,
  AiInterviewReview,
  JobInterviewReview,
  BigFiveReview,
  SettingsJobFeilds,
  SettingsDepartments,
  SettingsRoles,
  SettingsTeams,
  SettingsLocation,
  SettingsSkills,
  SettingsPrescreen,
  SettingsEvaluationParameter,
  SettingsEducation,
  SettingsQuestinnaire,
  SettingHiring,
  SettingsCertification,
  CommunicationRegistration,
  Dashboard,
  CandidateSlotSelection,
  CreateLicense,
  CreateLicenseForm,
  PersonalityReport,
  PersonalityForm,
  PersonalityTest,
  BigFiveFeedBackForm,
  Consultancy,
  ConsultancyUser,
  ConsultancyClient,
  CandidateSourcingReport,
  CandidateScreeningReport,
  CommunicationReport,
  ConsultancyClientUser,
  EnquiryList,
  EnquiryDetails,
  CreateEnquiryRegistration,
  QuestionView,
  CreateQuestionnaireFromQuestions,
  ViewCreatedQuestionFromQuestionBank,
  SettingsDocumentType,
  SettingsGroup,
  SettingsUserList,
  AvatarSettings,
  QuestionnaireCreation,
  AiActivityReview,
  GeneralReview,
  JobReport,
  PerformanceReport,
  PrivateRoute,
  PublicRoute,
} from 'Routes';
import Test1 from './Test/Test1.jsx';
import Test2 from './Test/Test2.jsx';
import JanusTest from 'pages/VideoInterview/JanusTest.jsx';
import Interview from 'pages/VideoInterview/Interview.js';
import PageLoader from 'Components/CommonComponents/PageLoader/PageLoader';
import PreviewPage from 'Components/Interview/AIInterviewFeedback/PreviewPage.js';
import Status404 from 'Components/CommonComponents/PageNotFound/Status404.js';
import InActiveLink from 'Components/CommonComponents/PageNotFound/InActiveLink.js';
import TestPDF from 'pages/Hr/Interviewer/TestPDF.js';
import AddJobNew from 'pages/Hr/Jobs/AddJobNew.js';
import CreateCandidate from 'pages/Hr/Candidates/CreateCandidate.js';
import SubmitScreen from 'pages/InHouseTest/SubmitScreen.js';
import InterviewDetails from 'pages/InHouseTest/InterviewDetails.js';
import { MyContext } from 'Context/context.js';
import JitsiMeet from 'pages/JitsiMeet/JitsiMeet.jsx';
import ListResumeTemplates from 'pages/ResumeBuilder/ListResumeTemplates.js';

const Router = () => {
  const [loading, setLoading] = useState(false);
  const { company_id, setCompanyID } = useContext(MyContext);
  useEffect(() => {
    console.log(company_id, 'company_id');
    setLoading(true);
    const timeOutId = setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [company_id]);
  return (
    <>
      {loading ? (
        <div className="h-60 flex items-center justify-center">
          <Spin />
        </div>
      ) : (
        <Routes>
          <Route element={<Layout />}>
            <Route
              exact
              path={routes.AI_INTERVIEW_REVIEW}
              element={
                <Suspense fallback={PageLoader}>
                  <AiInterviewReview />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.AI_INTERVIEW_REVIEW}/:interviewId`}
              element={
                <Suspense fallback={PageLoader}>
                  <AiInterviewReview />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.AI_PREVIEW_REVIEW}
              element={
                <Suspense fallback={PageLoader}>
                  <PreviewPage />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.JOB_INTERVIWER_REVIEW}
              element={
                <Suspense fallback={PageLoader}>
                  <JobInterviewReview />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.BIG_FIVE_REVIEW}/:reportId`}
              element={
                <Suspense fallback={PageLoader}>
                  <BigFiveReview />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.BIG_FIVE_REVIEW}/completed/:reportId`}
              element={
                <Suspense fallback={PageLoader}>
                  <BigFiveReview />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.INTERVIEWER}
              element={
                <Suspense fallback={PageLoader}>
                  <Interviewer />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.INTERVIEWER}/:interviewId`}
              element={
                <Suspense fallback={PageLoader}>
                  <Interviewer />
                </Suspense>
              }
            />
            <Route exact path="/" element={<Dashboard />} />
            <Route
              exact
              path={routes.REGISTRATION}
              element={<RegistrationManagement />}
            />
            <Route
              exact
              path={routes.VIEW_COMPANY_INFORMATION}
              element={<CompanyInformation />}
            />
            <Route
              exact
              path={`${routes.COMMUNICATION}/:tabName`}
              element={<Communication />}
            />
            <Route exact path="/hr/editJob/:jobId" element={<EditJob />} />
            <Route exact path="/hr/viewJob/:jobId" element={<ViewJob />} />
            <Route
              exact
              path={routes.ADD_JOB}
              element={
                <Suspense fallback={PageLoader}>
                  <AddJobNew />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.JOBS}/:status`}
              element={
                <Suspense fallback={PageLoader}>
                  <Jobs />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.JOB_DETAILS}/:jobId`}
              element={
                <Suspense fallback={PageLoader}>
                  <JobDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.JOB_DETAILS}/:jobId/:mainTabName`}
              element={
                <Suspense fallback={PageLoader}>
                  <JobDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.JOB_DETAILS}/:jobId/:mainTabName/:responseTabName`}
              element={
                <Suspense fallback={PageLoader}>
                  <JobDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.JOB_DETAILS}/:jobId/:mainTabName/type/:candidateTypeTabName`}
              element={
                <Suspense fallback={PageLoader}>
                  <JobDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.JOB_DETAILS}/:jobId/:mainTabName/level/:scheduleLevelName`}
              element={
                <Suspense fallback={PageLoader}>
                  <JobDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.JOB_DETAILS}/:jobId/:mainTabName/phase/:scoreCardLevelName`}
              element={
                <Suspense fallback={PageLoader}>
                  <JobDetails />
                </Suspense>
              }
            />
            {/* <Route
              exact
              path={`${routes.JOB_DETAILS}/:jobId/candidate-info/:candidateId/:infoTabName`}
              element={
                <Suspense fallback={PageLoader}>
                  <CandidatesInfoPage />
                </Suspense>
              }
            /> */}
            <Route
              exact
              path={`${routes.JOB_DETAILS}/:jobId/new-candidate`}
              element={
                <Suspense fallback={PageLoader}>
                  <CreateCandidate />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS}
              element={
                <Suspense fallback={PageLoader}>
                  <Settings />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.CANDIDATES}/:status`}
              element={
                <Suspense fallback={PageLoader}>
                  <Candidates />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.CREATE_NEW_CANDIDATE}
              element={
                <Suspense fallback={PageLoader}>
                  <CreateCandidate />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.CANDIDATES_INFO}/:candidateId/`}
              element={
                <Suspense fallback={PageLoader}>
                  <CandidatesInfoPage />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.CANDIDATES_INFO}/:candidateId/:infoTabName`}
              element={
                <Suspense fallback={PageLoader}>
                  <CandidatesInfoPage />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.QUESTION_BANK}
              element={
                <Suspense fallback={PageLoader}>
                  <QuestionBanks />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.CREATE_QUESTIONNAIRE_PAGE}
              element={
                <Suspense fallback={PageLoader}>
                  <CreateQuestionnairePage />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.CREATE_QUESTIONNAIRE_PAGE}/:questionBankId`}
              element={
                <Suspense fallback={PageLoader}>
                  <CreateQuestionnairePage />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.CREATE_EXCEL_QUESTIONNAIRE_PAGE}
              element={
                <Suspense fallback={PageLoader}>
                  <ConfigureQuestionnairePage />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.CONFIGURE_QUESTIONNAIRE_PAGE}/:questionBankId`}
              element={
                <Suspense fallback={PageLoader}>
                  <ConfigureQuestionnairePage />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.VIEW_QUESTION_BANK}/:questionnaireId`}
              element={
                <Suspense fallback={PageLoader}>
                  <ViewQuestionBankPage />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.VIEW_QUESTIONNAIRE}
              element={
                <Suspense fallback={PageLoader}>
                  <ViewQuestionnairePage />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.EDIT_QUESTIONNAIRE_PAGE}/:questionnaireId`}
              element={
                <Suspense fallback={PageLoader}>
                  <CreateQuestionnairePage />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_JOBFEILDS}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsJobFeilds />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_DEPARTMENTS}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsDepartments />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_ROLES}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsRoles />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_TEAMS}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsTeams />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_LOCATION}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsLocation />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_SKILLS}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsSkills />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_PRESCREEN}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsPrescreen />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_EVALUATION}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsEvaluationParameter />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_QUESTIONNAIRE}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsQuestinnaire />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_HIRING}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingHiring />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_CERTIFICATION}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsCertification />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.COMMUNICATION_REPORT}
              element={
                <Suspense fallback={PageLoader}>
                  <CommunicationReport />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.CANDIDATE_SOURCING_REPORT}
              element={
                <Suspense fallback={PageLoader}>
                  <CandidateSourcingReport />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.CANDIDATE_SCREENING_REPORT}
              element={
                <Suspense fallback={PageLoader}>
                  <CandidateScreeningReport />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_EDUCATION}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsEducation />
                </Suspense>
              }
            />
            <Route
              exact
              path="/resume-templates/:id"
              element={
                <Suspense fallback={PageLoader}>
                  <ListResumeTemplates />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.JOB_REPORT}
              element={
                <Suspense fallback={PageLoader}>
                  <JobReport />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.PERFORMANCE_REPORT}
              element={
                <Suspense fallback={PageLoader}>
                  <PerformanceReport />
                </Suspense>
              }
            />

            {/* -----------------------  Test routes starts ----------------------- */}
            <Route
              exact
              path={'test1'}
              element={
                <Suspense fallback={PageLoader}>
                  <Test1 />
                </Suspense>
              }
            />
            <Route
              exact
              path={'test2'}
              element={
                <Suspense fallback={PageLoader}>
                  <Test2 />
                </Suspense>
              }
            />
            {/* -----------------------  Test routes ends ----------------------- */}
            <Route
              exact
              path={routes.REGISTRATION_COMMUNICATION}
              element={
                <Suspense fallback={PageLoader}>
                  <CommunicationRegistration />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.DASHBOARD}
              element={
                <Suspense fallback={PageLoader}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.LICENSE}
              element={
                <Suspense fallback={PageLoader}>
                  <CreateLicense />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.CREATE_LICENSE}
              element={
                <Suspense fallback={PageLoader}>
                  <CreateLicenseForm />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.BIG_FIVE_FEEDBACKFORM}/:reportId`}
              element={
                <Suspense fallback={PageLoader}>
                  <BigFiveFeedBackForm />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_CONSULTANCY}
              element={
                <Suspense fallback={PageLoader}>
                  <Consultancy />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_CONSULTANCY_USER}
              element={
                <Suspense fallback={PageLoader}>
                  <ConsultancyUser />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_CONSULTANCY_CLIENT}
              element={
                <Suspense fallback={PageLoader}>
                  <ConsultancyClient />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_CONSULTANCY_CLIENT_USER}
              element={
                <Suspense fallback={PageLoader}>
                  <ConsultancyClientUser />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.ENQUIRY}
              element={
                <Suspense fallback={PageLoader}>
                  <EnquiryList />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.ENQUIRY_DETAILS}/:enquiryId`}
              element={
                <Suspense fallback={PageLoader}>
                  <EnquiryDetails />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.ENQUIRY_REGISTRATION}/:enquiryId`}
              element={
                <Suspense fallback={PageLoader}>
                  <CreateEnquiryRegistration />
                </Suspense>
              }
            />

            <Route
              exact
              path={routes.QUESTIONS}
              element={
                <Suspense fallback={PageLoader}>
                  <QuestionView />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.CREATE_QUESTIONNAIRE_FROM_QUESTIONS}
              element={
                <Suspense fallback={PageLoader}>
                  <CreateQuestionnaireFromQuestions />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.VIEW_CREATED_QUESTIONS}
              element={
                <Suspense fallback={PageLoader}>
                  <ViewCreatedQuestionFromQuestionBank />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.SETTINGS_DOCUMENT}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsDocumentType />
                </Suspense>
              }
            />

            <Route
              exact
              path={routes.SETTINGS_GROUP}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsGroup />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.SETTINGS_USER_LIST}/:groupId`}
              element={
                <Suspense fallback={PageLoader}>
                  <SettingsUserList />
                </Suspense>
              }
            />

            <Route
              exact
              path={routes.AVATAR_SETTINGS}
              element={
                <Suspense fallback={PageLoader}>
                  <AvatarSettings />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.QUESTIONNAIRE_PAGE_CREATION}
              element={
                <Suspense fallback={PageLoader}>
                  <QuestionnaireCreation />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.QUESTIONNAIRE_PAGE_CREATION}/:questionBankId`}
              element={
                <Suspense fallback={PageLoader}>
                  <QuestionnaireCreation />
                </Suspense>
              }
            />
            <Route exact path={'/testPdf'} element={<TestPDF />} />
          </Route>
          <Route
            exact
            path={`${routes.JOB_DETAILS}/:jobId/candidate-info/:candidateId/:infoTabName`}
            element={
              <Suspense fallback={PageLoader}>
                <CandidatesInfoPage />
              </Suspense>
            }
          />
          <Route
            exact
            path={`${routes.AI_PREVIEW_REVIEW}/activity/:interviewMethod/:interviewId/:evaluationId`}
            element={
              <Suspense fallback={PageLoader}>
                <AiActivityReview />
              </Suspense>
            }
          />
          <Route
            exact
            path={`${routes?.GENERAL_REVIEW}/:interviewId/:evaluationId`}
            element={
              <Suspense fallback={PageLoader}>
                <GeneralReview />
              </Suspense>
            }
          />
          <Route
            exact
            path={`${routes.BIG_FIVE_REVIEW}/activity/:reportId`}
            element={
              <Suspense fallback={PageLoader}>
                <BigFiveReview />
              </Suspense>
            }
          />
          <Route element={<PublicRoute />}>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/hr/jobs" element={<Login />} />
            <Route exact path="/invalid" element={<ErrorPage />} />
            <Route
              exact
              path={routes.PAGE_NOT_FOUND}
              element={
                <Suspense fallback={PageLoader}>
                  <Status404 />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.IN_ACTIVE_LINK}
              element={
                <Suspense fallback={PageLoader}>
                  <InActiveLink />
                </Suspense>
              }
            />
            <Route
              exact
              path={routes.CANDIDATE_ADD_SLOT}
              element={<CandidateSlotSelection />}
            />
            {/* TODO: Remove link and component when unnecessary  */}
            <Route exact path={'/janus-test'} element={<JanusTest />} />

            <Route
              exact
              path="/hr/employer-register/:user/:link_expiry"
              element={<EmployerRegister />}
            />
            <Route
              exact
              path="/hr/pre-screening/:user_id/:job/:token"
              element={<PreScreen />}
            />
            <Route exact path={routes.LOGIN} element={<Login />} />
            <Route
              exact
              path="/interview/ai/:token/"
              element={<Interview />}
            />
            <Route
              exact
              path="/interview/:token/"
              element={<InterviewDetails />}
            />
            <Route
              exact
              path="/yvi-meet/:roomName/:displayName"
              element={<JitsiMeet />}
            />
            <Route
              exact
              path="/interview/submittedInterview/:token/"
              element={<SubmitScreen />}
            />
            <Route
              exact
              path={`${routes.PERSONALITY_RESULT}/:id`}
              element={
                <Suspense fallback={PageLoader}>
                  <PersonalityReport />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.PERSONALITY_FORM}/:token`}
              element={
                <Suspense fallback={PageLoader}>
                  <PersonalityTest />
                </Suspense>
              }
            />
            <Route
              exact
              path={`${routes.PERSONALITY_TEST}/:token`}
              element={
                <Suspense fallback={PageLoader}>
                  <PersonalityForm />
                </Suspense>
              }
            />
            <Route exact path="/OTPForm" element={<OTPForm />} />
            <Route exact path="/VerifyOTP" element={<VerifyOTP />} />
            <Route
              exact
              path={routes.FORGOT_PASSWORD}
              element={<ForgotPassword />}
            />
            <Route
              exact
              path="/confirmPassword/:status/:uid/:token/"
              element={<ConfirmPassword />}
            />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default Router;
