import { useEffect, useState } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useForm } from 'react-hook-form';
import { jwtDecode } from 'jwt-decode';
import { Alert, message } from 'antd';
import { isEmpty } from 'lodash';

import 'react-phone-input-2/lib/style.css';
import routes from 'Routes';
import YVI_Logo from 'Assets/icons/YVI_Logo.png';
import { useLayoutStore } from 'Store/LayoutStore';
import authService from '../../Services/authService';
import { FULL_MENU_LIST } from 'Utilities/constants';
import { menus, adminMenus } from 'ServiceHooks/authHooks';
import Button from 'Components/CommonComponents/Button/Button';
import Progress from '../../Components/CommonComponents/Progress';
import Manchattingremotely from 'Assets/icons/Manchattingremotely.png';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import { filterMenusWithPermissions } from 'Utilities/componentSpecificUtilities';

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect_url = searchParams.get('redirect_url');
  const [logo, setLogo] = useState(null)
  const getLogo = async () => {
    await authService.fetchAppDetails()
        .then((oResponse) => {
          setLogo(oResponse?.data?.data?.app_logo);
        })
        .catch((err) => err);
  };
  useEffect(() => {
    getLogo();
  }, []);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    toggleNotification,
    setGridColumnPreference,
    setDashboardPreference,
  } = useLayoutStore((state) => ({
    toggleNotification: state.toggleNotification,
    setGridColumnPreference: state.setGridColumnPreference,
    setDashboardPreference: state.setDashboardPreference,
  }));

  const [isprogress, setProgress] = useState(false);
  const [isFormError, setFormError] = useState(false);
  const [strFormType, setFormType] = useState();
  const [strMessage, setMessage] = useState();
  const [isCountryLayout, setCountryLayout] = useState(false);
  const [phoneCode, setPhonecode] = useState();

  const onSubmit = async (data) => {
    if (isCountryLayout) {
      data['username'] = phoneCode + data.username;
    }
    //TODO: slated to be discarded
    data['company_domain'] = 'https://ndz.qa.yvi.ai/';
    setProgress(true);
    try {
      const response = await authService.userLogin(data);

      const authData = response.data.data;
      // localStorage.setItem('user', JSON.stringify(authData));
      // localStorage.setItem('email', authData.email);
      // localStorage.setItem('userId', authData.user_id);
      // localStorage.setItem(
      //   'mobile_number',
      //   JSON.stringify(authData.mobile_number),
      // );
      await Promise.all([
        localStorage.setItem('user', JSON.stringify(authData)),
        localStorage.setItem('email', authData.email),
        localStorage.setItem('userId', authData.user_id),
        localStorage.setItem(
          'mobile_number',
          JSON.stringify(authData.mobile_number),
        )
      ]);

      toggleNotification(true);
      if (!isEmpty(authData?.custom_table_settings)) {
        setGridColumnPreference(authData?.custom_table_settings);
      }
      if (!isEmpty(authData?.dashboard_drag_and_drop_permissions)) {
        setDashboardPreference(
          authData?.dashboard_drag_and_drop_permissions,
        );
      }
      const menuType = authData.is_superadmin ? adminMenus : menus;
      const permissions = authData.permissions;
      const permittedMenu = filterMenusWithPermissions(
        permissions,
        menuType,
        FULL_MENU_LIST,
      );
      setProgress(false);
      if (redirect_url && redirect_url !== 'null') {
        const decoded = jwtDecode(redirect_url);
        if (decoded?.status === 'REVIEW_PENDING') {
          navigate(`/hr/interviewer/?redirect_url=${redirect_url}`);
          // navigate(0);
        } else if (decoded?.redirect_url) {
          navigate(decoded?.redirect_url);
        }
      } else {
        navigate(permittedMenu[0].menuLink);
        // navigate(0);
      }

      message.open({
        type: 'success',
        content: 'Logged In.',
      });
    } catch (error) {
      const errorMessage = error?.response?.data?.error_info?.detail;
      console.log(error, 'errorMessage');
      setFormError(true);
      setFormType('error');
      setMessage(errorMessage);
      setProgress(false);
    }
  };

  const handelInputChange = (onChange, e) => {
    onChange(e);
    if (e.target.value !== '') {
      var test = Number(e.target.value);
      var test1 = Number.isInteger(test);
      if (test1) {
        setCountryLayout(true);
        setPhonecode('+91');
      } else {
        setCountryLayout(false);
        setPhonecode('');
      }
    } else {
      setCountryLayout(false);
      setPhonecode('');
    }
  };

  const handelChangePhone = (e) => {
    var value = `+${e}`;
    setPhonecode(value);
  };

  const { onChange, ...others } = register('username', {
    required: 'Please Enter The Username',
  });

  // console.log(redirect_url, 'redirect_url');

  return (
    <>
      <div className="w-full h-screen overflow_scroll scrollbar-hide">
        <div className="md:flex md:flex-row felx-col md:h-full  items-center justify-center ">
          <div className="md:w-1/2 sm:w-full flex items-center justify-center bg-primaryBgMain h-full">
            <div className="">
              <div className="flex items-center justify-center mb-20">
                <img src={logo? logo :YVI_Logo} alt="loginicon" width={90} />
              </div>
              <div>
                <img
                  src={Manchattingremotely}
                  alt="loginicon"
                  className=""
                />
              </div>
            </div>
          </div>
          <div className="md:w-1/2 sm:w-full flex items-center justify-center">
            <div className="w-3/4 px-20">
              <div className="mb-10 mt-14">
                <span className="text-black text-2xl font-semibold px-8 ">
                  Sign In
                </span>
              </div>
              {isFormError && (
                <Alert
                  message="Whoops!"
                  type={strFormType}
                  description={strMessage}
                  style={{ width: '90%', margin: 'auto' }}
                />
              )}
              <form
                className="customForm mb-8 flex flex-col"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <div className="flex">
                    <div className="w-full px-8 flex flex-col">
                      <p className="pb-2">Username</p>
                      <div className="flex flex-row w-full">
                        {isCountryLayout && (
                          <span className="w-[11%] mr-4">
                            <PhoneInput
                              value="91"
                              country="In"
                              inputStyle={{
                                width: '2%',
                                height: '42px',
                                paddingLeft: '0px',
                              }}
                              onChange={(e) => handelChangePhone(e)}
                            />
                          </span>
                        )}
                        <div className="w-full">
                          <Input
                            name="username"
                            control={control}
                            autocomplete={false}
                            className={'h-12'}
                            placeholder="Username or Email or Phone Number"
                            rules={{
                              required: 'This field is required',
                            }}
                            onChange={handelInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full px-8">
                    <Input
                      type="password"
                      name="password"
                      label="Password"
                      placeholder="Password"
                      control={control}
                      rules={{
                        required: 'This field is required',
                      }}
                      className="h-12"
                    />
                  </div>
                </div>

                <Button
                  type="ghost"
                  onClick={() => navigate(routes.FORGOT_PASSWORD)}
                  className="text-teal-600 text-base font-medium my-4 px-9 ml-auto"
                >
                  Forgot password?
                </Button>
                <div className="mx-10">
                  <button
                    className="bg-[#0A7F8F] text-white w-full h-[42px]"
                    type="submit"
                  >
                    LOGIN
                  </button>
                </div>
              </form>
              <span className="text-gray-600 flex items-center justify-center mb-6 ">
                Or
              </span>

              <div className="mx-10 mb-10">
                <Link
                  to={
                    redirect_url
                      ? `/OTPForm?redirect_url=${redirect_url}`
                      : '/OTPForm'
                  }
                >
                  <button className="text-teal-600 rounded-md border border-teal-600  w-full h-[42px] mt-2">
                    Login With OTP
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isprogress && <Progress />}
    </>
  );
};
export default Login;
