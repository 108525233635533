import React from 'react';
import { Input as AntInput, Form } from 'antd';
import { useController } from 'react-hook-form';

const { TextArea: AntTextArea } = AntInput;

const TextArea = ({
  label,
  placeholder = '',
  suffix,
  readOnly,
  // hook form specific
  name,
  control,
  defaultValue,
  rules = {},
  rows = 3,
  maxLength = 10000,
  showUpperLabel = true,
}) => {
  const {
    field: { ref, value, onBlur, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  const errorText = error?.message;
  const status = errorText ? 'error' : '';
  return (
    <Form.Item
      label={showUpperLabel ? label : null}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      help={errorText}
      validateStatus={status}
    >
      <AntTextArea
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        ref={ref}
        placeholder={placeholder}
        suffix={suffix}
        readOnly={readOnly}
        rows={rows}
        maxLength={maxLength}
      />
    </Form.Item>
  );
};

export default TextArea;
