import { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FiUpload } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { Empty, Modal, message, Upload, Spin } from 'antd';
import { useLocalStorage } from 'react-use';

import candidateService from '../../Services/candidateService';
import CTCField from './Fields/CTCField';
import Progress from '../CommonComponents/Progress';
import TextEdit from 'Components/CommonComponents/TextEdit';
import Input from 'Components/CommonComponents/ReactHooKForm/Input/Input';
import Button from 'Components/CommonComponents/Button/Button';
import Select from 'Components/CommonComponents/ReactHooKForm/Select/Select';
import RadioGroup from 'Components/CommonComponents/ReactHooKForm/RadioGroup/RadioGroup';
import {
  useCandidateInfo,
  useGetNationalities,
  useGetKnownLanguages,
  useCreateNewCandidate,
  useCandidateVideoUpload,
  useDeleteCandidateVideoUpload,
} from 'ServiceHooks/candidatesHooks';
import {
  BLOOD_GROUP_TYPES,
  AVAILABILITY_TYPES,
  MARITAL_STATUS_TYPES,
  DEFAULT_ERROR_MESSAGE,
  JOB_ACTIVITY_LOGGER_TYPES,
} from 'Utilities/constants';
import 'Styles/commonStyles.scss';
import playImg from '../../Assets/images/paly.svg';
import 'react-phone-input-2/lib/style.css';

import {
  useCreateActivityLoggerId,
  useRefetchJobDetailsMetadata,
} from 'ServiceHooks/jobHooks';
import createActivityLoggerId from 'Utilities/Jobs/createActivityLoggerId';
import Checkbox from 'Components/CommonComponents/ReactHooKForm/Checkbox/Checkbox';
import CountryCodeInput from 'Components/CommonComponents/Input/CountryCodeInput';
import { positiveIntegerValidation } from 'Utilities/utils';

const EditCandidate = (props) => {
  const { manualUpload } = props;
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [userSettings] = useLocalStorage('user');

  const jobId = params?.jobId;
  const isCandidatePage = location.pathname.includes('candidates');
  const isJobDetailsPage = location.pathname.includes('job-details');
  const { mutate: sendActivityLog } = useCreateActivityLoggerId({});

  const [file, setFile] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [isprogress, setProgress] = useState(false);
  const [oWorkingCondtionData, setWorkingCondition] = useState();
  const [nationalitySearch, setNationalitySearch] = useState('');
  const [languagesSearch, setLanguagesSearch] = useState('');
  const [nLocationId, setLocationId] = useState();
  const [skillsList, setSkillsList] = useState();
  const [certificationList, setCertificationList] = useState();
  const [qualificationList, setQualificationList] = useState();
  const [responseError, setResponseError] = useState();
  const [getCandidateInfo, setCandidateInfo] = useState();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [isVideoModalOpen, setVideoModel] = useState(false);
  const [isDeleteVideo, setDeleteVideo] = useState(false);

  const {
    clearErrors,
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      availability: '',
    },
  });

  useEffect(() => {
    register('about_me', { required: false });
    register('recruiter_notes', { required: false });
  }, [register]);

  const onTextAreaChange = (formName, text) => {
    if (!isEmpty(errors?.[formName]) && text.length > 0) {
      clearErrors(formName);
    }
    setValue(formName, text);
  };

  const { refetch: refetchCandidates } = useCandidateInfo({
    paramsData: {
      candidateId: params?.candidateId,
    },
    enabled: false,
  });

  const { refetchCandidates: refetchCandidateMetadata } =
    useRefetchJobDetailsMetadata();

  useEffect(() => {
    getWorkingCondition();
    if (!manualUpload) {
      console.log("enter userinfo1");
      getUserInfo();
    }
  }, []);

  const onCancel = () => {
    if (manualUpload) {
      setFile([]);
      reset();
      navigate(-1);
    } else {
      props.onClose(false);
    }
  };

  const getWorkingCondition = async () => {
    await candidateService
      .workingConditionData()
      .then((oResponse) => {
        var arr = [];
        oResponse.data.data.map((oOption) => {
          arr.push({
            key: oOption.id,
            value: oOption.condition,
          });
        });
        setWorkingCondition(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    mutate: postCandidateVideoData,
    status: postCandidateVideoDataStatus,
  } = useCandidateVideoUpload({
    onSuccess: () => {
      // refetch table on upload success
      console.log("enter userinfo2");
      getUserInfo();
      setProgress(false);
      messageApi.open({
        type: 'success',
        content: 'Upload successful',
      });
    },
    onError: (error, variables, context) => {
      setProgress(false);
      messageApi.open({
        type: 'error',
        content: 'Whoops! Failed To upload',
      });
    },
  });

  const { mutate: deleteVideoData, status: deleteVideoDataStatus } =
    useDeleteCandidateVideoUpload({
      onSuccess: () => {
        // refetch table on upload success
        console.log("enter userinfo3");
        getUserInfo();
        setProgress(false);
        messageApi.open({
          type: 'success',
          content: 'Deleted successfully',
        });
      },
      onError: (error, variables, context) => {
        setProgress(false);
        messageApi.open({
          type: 'error',
          content: 'Whoops! Failed To delete',
        });
      },
    });

  const { mutate: createNewCandidate, isLoading: isCreating } =
    useCreateNewCandidate({
      onSuccess: async () => {
        setFile([]);
        await messageApi.open({
          type: 'success',
          content: 'Candidate Created',
        });
        await refetchCandidateMetadata();
        navigate(-1);
      },
      onError: async (error) => {
        setProgress(false);
        const errorsObj = error?.response?.data?.error_info;
        Object.keys(errorsObj).forEach((field) => {
          if (field !== 'candidate_credentials') {
            const messages = errorsObj?.[field];
            setError(field, {
              type: 'server',
              message: messages?.join('. '),
            });
            messageApi.open({
              type: 'error',
              content: messages ?? DEFAULT_ERROR_MESSAGE,
            });
          } else {
            Object.values(errorsObj[field]).forEach((message) => {
              messageApi.open({
                type: 'error',
                content: message,
              });
            });
          }
        });
      },
    });

  const { data: nationalities, isFetching: isNationalitiesFetching } =
    useGetNationalities({
      params: {
        search: nationalitySearch,
      },
      enabled: true,
    });

  const { data: languages, isFetching: isLanguagesFetching } =
    useGetKnownLanguages({
      params: {
        search: languagesSearch,
      },
      enabled: true,
    });

  const nationalityOptions = useMemo(() => {
    if (isEmpty(nationalities?.results)) return [];
    const options = nationalities?.results.map((item) => {
      return {
        label: item.nationality,
        value: item.id,
      };
    });
    return options;
  }, [nationalities]);

  const languagesOptions = useMemo(() => {
    if (isEmpty(languages?.results)) return [];
    const options = languages?.results.map((item) => {
      return {
        label: item.language,
        value: item.id,
      };
    });
    return options;
  }, [languages]);

  const filterFile = (file) => {
    const types = [
      'application/pdf',
      'application/rtf',
      'application/msword',
      'application/doc',
      'application/ms-doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    if (types.includes(file.type)) {
      clearErrors('filename');
      setValue('filename', file?.name);
      //AntD's Upload needs arrays.
      setFile([file]);
    } else {
      setFile([]);
      setError('filename', {
        message: 'Unsupported File Format',
      });
    }

    //Stops AntD's automatic post request
    return false;
  };

  const addSearchOption = (responseData) => {
    var arr = [];
    responseData.map((oOption) => {
      arr.push({
        key: oOption.option_id,
        value: oOption.option_value,
      });
    });
    return arr;
  };

  const getSkillSelectDefaultValue = (defaultValue) => {
    if (defaultValue) {
      var options = addSearchOption(defaultValue);
      setSkillsList(options);
      return options;
    }
  };

  const getCertificationSelectDefaultValue = (defaultValue) => {
    if (defaultValue) {
      var options = addSearchOption(defaultValue);
      setCertificationList(options);
      return options;
    }
  };

  const getEducationSelectDefaultValue = (defaultValue) => {
    if (defaultValue) {
      var options = addSearchOption(defaultValue);
      setQualificationList(options);
      return options;
    }
  };

  const getUserInfo = async () => {
    setProgress(true);
    const params = {
      candidateId: props.nCandidateId
    }
    await candidateService
      .getCandidateInfo(params)
      .then((response) => {
        setCandidateInfo(response.data.data);
        setUserInfo(response.data.data);

        const initialCurrencyCode =
          response?.data?.data?.ctc_currency_code ??
          userSettings?.ctc_currency_code ??
          '';

        const { languages } = response?.data?.data;

        setValue(
          'first_name',
          response.data.data.candidate_credentials.first_name,
        );
        setValue(
          'last_name',
          response.data.data.candidate_credentials.last_name,
        );
        setValue('ctc_currency_code', initialCurrencyCode);
        setValue('current_ctc', response.data.data.current_ctc);
        setValue('expected_ctc', response.data.data.expected_ctc);
        setValue('email', response.data.data.candidate_credentials.email);
        setValue(
          'mobile_number',
          response.data.data.candidate_credentials.national_number,
        );
        setValue(
          'country_code',
          response.data.data.candidate_credentials.country_code
            ? response.data.data.candidate_credentials.country_code
            : '',
        );
        setValue('blood_group', response.data.data.blood_group);
        setValue('marital_status', response.data.data.marital_status);
        setValue('nationality', response.data.data.nationality.id);
        setValue(
          'alternative_mobile_number',
          response.data.data.candidate_credentials
            .alternative_national_number,
        );
        setValue(
          'alternate_country_code',
          response.data.data.candidate_credentials.alternate_country_code
            ? response.data.data.candidate_credentials
                .alternate_country_code
            : '',
        );
        setValue('notice_period', response.data.data.notice_period);
        setValue(
          'negotible_notice_period',
          response.data.data.negotible_notice_period,
        );
        setValue('availability', response.data.data.availability);
        setValue('subscription', response.data.data.subscription);
        setValue('status', response.data.data.status);
        setValue('dob', response.data.data.dob);
        setValue(
          'preferred_working_condition',
          response.data.data.preferred_working_condition?.value,
        );

        setValue(
          'skills',
          getSkillSelectDefaultValue(response.data.data.skills),
        );
        setValue(
          'certifications',
          getCertificationSelectDefaultValue(
            response.data.data.certifications,
          ),
        );
        setValue(
          'qualifications',
          getEducationSelectDefaultValue(
            response.data.data.qualifications,
          ),
        );

        const initialLanguages = languages?.map((item) => ({
          key: item.option_id,
          value: item.option_id,
          label: item.option_value,
        }));
        setValue('languages', initialLanguages);
        setValue('about_me', response.data.data.about_me);
        setValue('recruiter_notes', response.data.data.recruiter_notes);
        setValue('is_career_break', response.data.data.is_career_break);
        setValue(
          'is_frequent_switch',
          response.data.data.is_frequent_switch,
        );

        setProgress(false);
      })
      .catch((err) => {
        console.error('Fetching Error: ', err);
        setProgress(false);
      });
  };

  const onSkillSearch = async (value) => {
    let payload = {
      searchValue: value,
    };
    await candidateService
      .getSkillsList(payload)
      .then((res) => {
        let arr = [];
        res?.data?.data?.results.map((oOption) => {
          arr.push({
            key: oOption.option_id,
            value: oOption.option_value,
          });
        });
        setSkillsList(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCertificationSearch = async (value) => {
    let payload = {
      searchValue: value,
    };
    await candidateService
      .getCertificatesList(payload)
      .then((res) => {
        let arr = [];
        res?.data?.data?.results.map((oOption) => {
          arr.push({
            key: oOption.option_id,
            value: oOption.option_value,
          });
        });
        setCertificationList(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onQualificationSearch = async (value) => {
    let payload = {
      searchValue: value,
    };
    await candidateService
      .getQualificationsList(payload)
      .then((res) => {
        let arr = [];
        res?.data?.data?.results.map((oOption) => {
          arr.push({
            key: oOption.option_id,
            value: oOption.option_value,
          });
        });
        setQualificationList(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSelectedIds = (selectedData) => {
    if (isEmpty(selectedData)) return [];
    const selectedId = selectedData.map((item) => {
      return item.key;
    });
    return selectedId;
  };

  const onSubmit = async (data, e) => {
    console.log("ENTER INSIDE HERE>>>");
    setProgress(true);
    const logger_id = createActivityLoggerId();
    if (manualUpload) {
      const json = {
        first_name: data.first_name,
        last_name: data.last_name,
        // middle_name: data.middle_name, //TODO: Bring it back when it is needed.
        email: data.email,
        // mobile_number: `${data.country_code}${data.mobile_number}`,
        mobile_number: data.mobile_number
          ? data.country_code
            ? `+${data.country_code}${data.mobile_number}`
            : `+91${data.mobile_number}`
          : '',
        alternative_mobile_number: data.alternative_mobile_number
          ? data.alternate_country_code
            ? `+${data.alternate_country_code}${data.alternative_mobile_number}`
            : `+91${data.alternative_mobile_number}`
          : '',
        ...(data?.current_ctc && { current_ctc: data.current_ctc }),
        ...(data?.expected_ctc && { expected_ctc: data.expected_ctc }),
        ...(data?.ctc_currency_code && {
          ctc_currency_code: data.ctc_currency_code.value,
        }),
        // ...(data?.alternative_mobile_number && {
        //   alternative_mobile_number: `+${data.alternate_country_code}${data.alternative_mobile_number}`,
        // }),
        ...(data?.dob && { dob: data.dob }),
        ...(data?.notice_period && { notice_period: data.notice_period }),
        ...(data?.negotible_notice_period && {
          negotible_notice_period: data.negotible_notice_period,
        }),

        ...(data?.blood_group && { blood_group: data.blood_group?.value }),
        ...(data?.marital_status && {
          marital_status: data.marital_status?.value,
        }),
        ...(data?.nationality && { nationality: data.nationality?.value }),

        candidate_details: JSON.stringify({
          job_id: Number(jobId),
          skills: getSelectedIds(data.skills),
          qualifications: getSelectedIds(data.qualifications),
          certifications: getSelectedIds(data.certifications),
          ...(!!data?.about_me && { about_me: data.about_me }),
          ...(!!data?.recruiter_notes && {
            recruiter_notes: data.recruiter_notes,
          }),
        }),
        ...(data?.preferred_working_condition && {
          preferred_working_condition:
            data?.preferred_working_condition?.key,
        }),
        ...(!isEmpty(data?.languages) && {
          languages: getSelectedIds(data?.languages),
        }),
        is_career_break: data.is_career_break,
        is_frequent_switch: data.is_frequent_switch,
      };
      if (data?.availability) {
        json['availability'] = data.availability;
      }

      const formData = new FormData();

      Object.entries(json).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (!isEmpty(file)) {
        const fileObject = file[0];
        formData.append('resume', fileObject);
      }
      const queryParams = {
        logger_id,
        ...(isJobDetailsPage && { job_id: jobId }),
      };
      if (!isCandidatePage) {
        const log = {
          logger_id,
          job: jobId,
          activity: JOB_ACTIVITY_LOGGER_TYPES.CANDIDATES_ADDED,
        };
        sendActivityLog(log);
      }

      createNewCandidate({
        body: formData,
        ...(!isCandidatePage ? { params: queryParams } : {}),
      });
    } else {
      if (data.skills) {
        data.skills = getSelectedIds(data.skills);
      }
      if (data.qualifications) {
        data.qualifications = getSelectedIds(data.qualifications);
      }
      if (data.certifications) {
        data.certifications = getSelectedIds(data.certifications);
      }
      if (data.languages) {
        data.languages = getSelectedIds(data.languages);
      }
      if (data.preferred_working_condition) {
        data.preferred_working_condition = data
          ?.preferred_working_condition?.key
          ? data?.preferred_working_condition?.key
          : getCandidateInfo.preferred_working_condition.id;
      }

      const json = {
        candidate_credentials: {
          first_name: data.first_name,
          middle_name: data.middle_name,
          last_name: data.last_name,
          email: data.email,
          mobile_number: data.mobile_number
            ? data.country_code
              ? `+${data.country_code}${data.mobile_number}`
              : `+91${data.mobile_number}`
            : '',
          alternative_mobile_number: data.alternative_mobile_number
            ? data.alternate_country_code
              ? `+${data.alternate_country_code}${data.alternative_mobile_number}`
              : `+91${data.alternative_mobile_number}`
            : '',
        },
        current_ctc: data.current_ctc,
        expected_ctc: data.expected_ctc,
        ...(data?.ctc_currency_code && {
          ctc_currency_code: data.ctc_currency_code.value,
        }),
        location: nLocationId,
        dob: data.dob,
        qualifications: data.qualifications,
        certifications: data.certifications,
        blood_group: data.blood_group?.value,
        marital_status: data.marital_status?.value,
        nationality: data.nationality?.value,
        skills: data.skills,
        languages: data.languages,
        notice_period: data.notice_period,
        negotible_notice_period: data.negotible_notice_period,
        availability: data.availability,
        preferred_working_condition: data?.preferred_working_condition,
        ...(data?.about_me && { about_me: data.about_me }),
        ...(data?.recruiter_notes && {
          recruiter_notes: data.recruiter_notes,
        }),
        is_career_break: data.is_career_break,
        is_frequent_switch: data.is_frequent_switch,
      };
      updateData(json);
    }
  };

  const updateData = async (json) => {
    try {
      const response = await candidateService.updateCandidate(
        json,
        props.nCandidateId,
      );
      refetchCandidates();
      await messageApi.open({
        type: 'success',
        content: 'Successfully Updated',
      });
      setProgress(false);
      props.onClose();
    } catch (error) {
      const errorsObj = error.response.data.error_info;
      Object.keys(errorsObj).forEach((field) => {
        if (field !== 'candidate_credentials') {
          const messages = errorsObj?.[field];
          setError(field, {
            type: 'server',
            message: messages?.join('. '),
          });
          messageApi.open({
            type: 'error',
            content: message,
          });
        } else {
          Object.values(errorsObj[field]).forEach((message) => {
            messageApi.open({
              type: 'error',
              content: message,
            });
          });
        }
      });
      setProgress(false);
    }
  };

  const updateCandidateVideo = (e) => {
    setProgress(true);
    const file = e.target.files[0];
    let cId = props.nCandidateId;
    const params = { file, cId };
    postCandidateVideoData(params);
  };

  const deleteCandidateVideo = () => {
    let cId = props.nCandidateId;
    const params = { cId };
    deleteVideoData(params);
  };

  return (
    <div className="edit_candidate_maindiv">
      {messageContextHolder}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="editCandidate_form">
          <div className="form_content">
            <Input
              name="first_name"
              label={
                <p>
                  First Name<span className="text-errorMain">*</span>
                </p>
              }
              control={control}
              placeholder="First Name"
              rules={{
                required: 'This field is required',
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: 'Please enter letters only',
                },
              }}
            />
          </div>
          <div className="form_content">
            <Input
              name="last_name"
              label={
                <p>
                  Last Name<span className="text-errorMain">*</span>
                </p>
              }
              control={control}
              placeholder="Last Name"
              rules={{
                required: 'This field is required',
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: 'Please enter letters only',
                },
              }}
            />
          </div>
          <div className="form_content">
            <Input
              name="email"
              label={
                <p>
                  Email ID<span className="text-errorMain">*</span>
                </p>
              }
              control={control}
              placeholder="Email ID"
              rules={{
                required: 'This field is required',
              }}
            />
          </div>
          <div className="form_content">
            <CountryCodeInput
              name="mobile_number"
              country_code_name="country_code"
              country_code_value={watch('country_code')}
              value={watch('mobile_number')}
              setValue={setValue}
              label="Contact Number"
              placeholder="Contact Number"
              required={true}
              control={control}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please enter only numbers',
                },
              }}
            />
          </div>
          <div className="form_content">
            <CountryCodeInput
              name="alternative_mobile_number"
              country_code_name="alternate_country_code"
              country_code_value={watch('alternate_country_code')}
              value={watch('alternative_mobile_number')}
              setValue={setValue}
              label="Alternative Contact Number"
              placeholder="Alternative Contact Number"
              required={false}
              control={control}
              rules={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please enter only numbers',
                },
              }}
            />
          </div>
          <div className="form_content">
            <CTCField control={control} />
          </div>
          <div className="form_content">
            <Input
              type="date"
              name="dob"
              label={<p>Date of Birth</p>}
              control={control}
              placeholder="Date of Birth"
              max={dayjs().format('DD-MM-YYYY')}
            />
          </div>
          <div className="form_content">
            <Select
              allowClear
              label={<p>Blood Group</p>}
              placeholder="Blood Group"
              options={BLOOD_GROUP_TYPES}
              name="blood_group"
              showArrow={true}
              control={control}
            />
          </div>
          <div className="form_content">
            <Select
              allowClear
              label={<p>Marital Status</p>}
              placeholder="Marital Status"
              options={MARITAL_STATUS_TYPES}
              name="marital_status"
              showArrow={true}
              control={control}
            />
          </div>
          <div className="form_content">
            <Select
              allowClear
              showSearch
              showArrow={true}
              control={control}
              name="nationality"
              placeholder="Nationality"
              label={<p>Nationality</p>}
              options={nationalityOptions}
              fetching={isNationalitiesFetching}
              onSearch={(text) => setNationalitySearch(text)}
              onClear={() => {
                setValue('nationality', undefined);
              }}
            />
          </div>
          <div className="form_content">
            <Select
              allowClear
              showSearch
              showArrow={true}
              control={control}
              name="languages"
              mode={'multiple'}
              placeholder="Spoken Languages"
              label={<p>Known Languages</p>}
              options={languagesOptions}
              fetching={isLanguagesFetching}
              onSearch={(text) => setLanguagesSearch(text)}
              onClear={() => {
                setValue('languages', undefined);
              }}
            />
          </div>
          <div className="form_content">
            <Input
              name="notice_period"
              label={<p>Notice Period</p>}
              control={control}
              placeholder="in days"
              rules={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Invalid Type',
                },
              }}
            />
          </div>
          <div className="form_content">
            <Input
              name="negotible_notice_period"
              label={<p>Negotiable Notice Period</p>}
              control={control}
              placeholder="in days"
              rules={{
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Invalid Type',
                },
              }}
            />
          </div>
          <div className="form_content">
            <Select
              label={<p>Preferred Working Condition</p>}
              placeholder="Preferred Working Condition"
              options={oWorkingCondtionData}
              name={'preferred_working_condition'}
              control={control}
              showArrow={true}
              allowClear
            />
          </div>
          <div className="form_content">
            <RadioGroup
              options={AVAILABILITY_TYPES}
              control={control}
              name={'availability'}
              label={<p>Availability</p>}
            />
          </div>
          <div className="form_content">
            <Select
              label={<p>Education</p>}
              placeholder="Education"
              options={qualificationList}
              name="qualifications"
              control={control}
              showArrow={true}
              mode="multiple"
              onSearch={(e) => {
                onQualificationSearch(e);
              }}
            />
          </div>
          <div className="form_content">
            <Select
              label={<p>Certification</p>}
              placeholder="Certification"
              options={certificationList}
              name="certifications"
              control={control}
              showArrow={true}
              mode="multiple"
              onSearch={(e) => {
                onCertificationSearch(e);
              }}
            />
          </div>
          <div className="form_content">
            <Select
              label={<p>Skills</p>}
              placeholder="Skills"
              options={skillsList}
              name={'skills'}
              control={control}
              showArrow={true}
              mode="multiple"
              onSearch={(e) => {
                onSkillSearch(e);
              }}
            />
            <div className="my-2 flex items-center gap-4">
              <Checkbox
                label="Career Break"
                name="is_career_break"
                control={control}
                showUpperLabel={false}
              />
              <Checkbox
                label="Frequent Switch"
                name="is_frequent_switch"
                control={control}
                showUpperLabel={false}
              />
            </div>
          </div>
          <div className="form_content">
            <div>About Me</div>
            <TextEdit
              initialValue={!manualUpload ? getValues('about_me') : ''}
              onChange={(value) => onTextAreaChange('about_me', value)}
            />
            {errors?.about_me && (
              <div className="text-errorMain">
                {errors?.about_me?.message}
              </div>
            )}
          </div>
          <div className="form_content">
            <div>Notes</div>
            <TextEdit
              initialValue={
                !manualUpload ? getValues('recruiter_notes') : ''
              }
              onChange={(value) =>
                onTextAreaChange('recruiter_notes', value)
              }
            />
            {errors?.recruiter_notes && (
              <div className="text-errorMain">
                {errors?.recruiter_notes?.message}
              </div>
            )}
          </div>
          {manualUpload ? (
            <div className="form_content max-w-xs">
              <div>
                Resume
                <span className={'text-gray-400 text-sm font-light'}>
                  {'  '}(Accept: .pdf, .doc, .docx, .rtf)
                </span>
              </div>
              <Upload
                {...register('filename')}
                maxCount={1}
                fileList={file}
                action={null}
                multiple={false}
                className="w-full"
                onChange={() => {}}
                accept="application/pdf, application/rtf, application/msword, application/doc, application/ms-doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                beforeUpload={(file) => filterFile(file)}
                onRemove={() => setFile([])}
              >
                <Button
                  className="mt-1"
                  isSecondary
                  icon={<FiUpload color="gray" />}
                  children={'Upload'}
                />
              </Upload>
              {errors?.filename && (
                <div className="text-[#ff4d4f]">
                  {errors?.filename?.message}
                </div>
              )}
            </div>
          ) : (
            <div className="form_content">
              <h5>Profile Video </h5>
              <div className="flex gap-5">
                <div className="w-1/3 mt-2">
                  {userInfo?.profile_video_absolute_url ? (
                    <div style={{ position: 'relative', width: '100%' }}>
                      <video
                        className="w-full h-full"
                        controlsList="nodownload"
                        onContextMenu={(e) => e.preventDefault()}
                      >
                        <source
                          src={userInfo?.profile_video_absolute_url}
                          type="video/mp4"
                        />
                        <source
                          src={userInfo?.profile_video_absolute_url}
                          type="video/ogg"
                        />
                        Your browser does not support the video tag.
                      </video>
                      <div className="videoPlay">
                        <img
                          src={playImg}
                          alt="play"
                          onClick={() => setVideoModel(!isVideoModalOpen)}
                        />
                      </div>
                    </div>
                  ) : (
                    <Empty
                      description={
                        <p className="text-xs">
                          <span className="font-semibold">
                            Profile Video is Not Updated
                          </span>
                        </p>
                      }
                    />
                  )}
                </div>

                <div className="float-right flex">
                  <Button isSecondary className="editUploadBtn pr-5">
                    <label for="canVideo" className="pr-1">
                      <input
                        type="file"
                        id="canVideo"
                        style={{ display: 'none' }}
                        onChange={(e) => {
                          updateCandidateVideo(e);
                        }}
                      />
                      Update
                    </label>
                  </Button>

                  <Button
                    className="editUploadBtn ml-1"
                    danger
                    onClick={() => {
                      setDeleteVideo(!isDeleteVideo);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="float-right">
          <Button isSecondary onClick={onCancel} disabled={isCreating}>
            Cancel
          </Button>
          <Button
            type="submit"
            className="ml-4 text-[#fff] bg-[#0a7f8f]"
            htmlType="submit"
            disabled={isCreating}
          >
            {isCreating ? (
              <Spin className="loadingSpinner" size="default" />
            ) : (
              'Submit'
            )}
          </Button>
        </div>
      </form>
      {isprogress && <Progress />}

      {/* video model */}

      <Modal
        className="activitiesModal"
        width={800}
        open={isVideoModalOpen}
        title={null}
        onCancel={() => {
          setVideoModel(!isVideoModalOpen);
        }}
        footer={null}
        destroyOnClose
      >
        <video
          className="w-full h-full"
          controls
          autoPlay={true}
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
        >
          <source
            src={userInfo?.profile_video_absolute_url}
            type="video/mp4"
          />
          <source
            src={userInfo?.profile_video_absolute_url}
            type="video/ogg"
          />
          Your browser does not support the video tag.
        </video>
      </Modal>

      {/* delete video model */}

      <Modal
        className=""
        width={500}
        open={isDeleteVideo}
        title={null}
        onCancel={() => {
          setDeleteVideo(!isDeleteVideo);
        }}
        footer={null}
        destroyOnClose
      >
        <div className="p-5 text-center">
          <div>Are you sure you want to delete the video</div>
          <div className="mt-5">
            <Button
              className="popUpBtn"
              type="primary"
              onClick={() => {
                deleteCandidateVideo();
              }}
            >
              ok
            </Button>

            <Button
              className="popUpBtn"
              danger
              onClick={() => {
                setDeleteVideo(!isDeleteVideo);
              }}
            >
              cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default EditCandidate;
