import { useQuery, useMutation } from '@tanstack/react-query';
import communicationService, {
  getWhatsAppTemplatePreview,
} from 'Services/communicationService';

export const GET_OFFER_LETTER_TRANSACTION_TYPES =
  'GET_OFFER_LETTER_TRANSACTION_TYPES';
const useGetOfferTransactionTypes = (config) => {
  const query = useQuery({
    queryKey: [GET_OFFER_LETTER_TRANSACTION_TYPES],
    queryFn: async () => {
      const response =
        await communicationService.getOfferTransactionTypes();
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

export const GET_COMMUNICATION_TRANSACTIONS =
  'GET_COMMUNICATION_TRANSACTIONS';
const useGetCommunicationTransactions = ({ params = {}, ...rest }) => {
  const query = useQuery({
    queryKey: [GET_COMMUNICATION_TRANSACTIONS],
    queryFn: async () => {
      const response =
        await communicationService.getTranscationData(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...rest,
  });

  return query;
};

export const GET_TRANSACTIONS_TEMPLATE = 'GET_TRANSACTIONS_TEMPLATE';
const useGetCommunicationTransactionsTemplate = ({ params, ...rest }) => {
  const query = useQuery({
    queryKey: [
      GET_TRANSACTIONS_TEMPLATE,
      params?.transaction_key,
      params?.is_image,
    ],
    queryFn: async () => {
      const response =
        await communicationService.getTranscationTemplateData(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...rest,
  });

  return query;
};

export const GET_RECRUITER_LIST = 'GET_RECRUITER_LIST';
const useGetRecruiters = (config) => {
  const { params, ...otherConfigs } = config;
  const query = useQuery({
    queryKey: [
      GET_RECRUITER_LIST,
      params?.job_id,
      params?.search,
      params?.job,
      params?.recruiter,
      params?.candidate,
      params?.sub_user,
    ],
    queryFn: async () => {
      const response = await communicationService.getRecruiters(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...otherConfigs,
  });
  return query;
};

export const GET_INTERVIEWERS = 'GET_INTERVIEWERS';
const useGetInterviewers = (config) => {
  const { params, ...otherConfigs } = config;
  const query = useQuery({
    queryKey: [
      GET_INTERVIEWERS,
      params?.job_id,
      params?.search,
      params?.job,
      params?.recruiter,
      params?.candidate,
      params?.sub_user,
    ],
    queryFn: async () => {
      const response = await communicationService.getInterviewers(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...otherConfigs,
  });
  return query;
};

export const GET_WHATSAPP_PREVIEW = 'GET_WHATSAPP_PREVIEW';
export const useGetWhatsAppPreview = ({ params, ...rest }) => {
  const query = useQuery({
    queryKey: [GET_WHATSAPP_PREVIEW, params?.template_name],
    queryFn: async () => {
      const response = await getWhatsAppTemplatePreview(params);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

export const GET_COMMUNICATION_LOGS_LIST = 'GET_COMMUNICATION_LOGS_LIST';
const useGetCommunicationLogsList = (config) => {
  const { params, filter = '', ...rest } = config;

  const query = useQuery({
    queryKey: [
      GET_COMMUNICATION_LOGS_LIST,
      params?.limit,
      params?.offset,
      params?.search,
      params?.end_date,
      params?.start_date,
      // params?.job,
      // params?.recruiter,
      // params?.candidate,
      // params?.sub_user,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        end_date: params?.end_date,
        start_date: params?.start_date,
        // job: params?.job,
        // recruiter: params?.recruiter,
        // candidate: params?.candidate,
        // sub_user: params?.sub_user,
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      if (filter?.length) {
        filter?.map((item) => {
          const filterPhrases = item?.split('=');
          let filterKey;
          let term;
          if (filter) {
            [filterKey, term] = filterPhrases;
            queryParams[filterKey] = term;
            queryParams[filterKey] = term;
          }
        });
      }


      const response =
        await communicationService.getCommunicationLogsWithListView(
          queryParams,
        );
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

export const GET_COMMUNICATION_LOGS_CARDS = 'GET_COMMUNICATION_LOGS_CARDS';
const useGetCommunicationLogsCards = (config) => {
  const { params, ...otherConfigs } = config;
  const query = useQuery({
    queryKey: [
      GET_COMMUNICATION_LOGS_CARDS,
      params?.start_date,
      params?.end_date,
      params?.job,
      params?.recruiter,
      params?.candidate,
      params?.sub_user,
    ],
    queryFn: async () => {
      const response =
        await communicationService.getCommunicationLogsWithCardView(
          params,
        );
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...otherConfigs,
  });
  return query;
};

/* 
  Comm. > WhatsApp Tab: updating a template.
*/
export const useUpdateWhatsAppTemplate = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ body, params, id }) => {
      const details = await communicationService.addWhatsAppTemplate(
        body,
        params,
        id,
      );
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

/* 
  Comm. > WhatsApp Tab: adds a new template name.
*/
export const useCreateWhatsAppTemplate = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ body, params }) => {
      const details = await communicationService.createWhatsAppTemplate(
        body,
        params,
      );
      return details?.data;
    },
    ...config,
  });
  return mutation;
};
export const GET_PERFORMANCE_REPORT = 'GET_PERFORMANCE_REPORT';

const useGetPerformanceReport = (config) => {
  const { params, filter = '', ...otherConfigs } = config;
  const query = useQuery({
    queryKey: [
      GET_PERFORMANCE_REPORT,
      params?.limit,
      params?.offset,
      params?.type,
      params?.start_date,
      params?.end_date,
      params?.search,
      params?.order_by,
      ...(filter ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        end_date: params?.end_date,
        start_date: params?.start_date,
        type: params?.type,
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      if (filter?.length) {
        filter?.map((item) => {
          const filterPhrases = item?.split('=');
          let filterKey;
          let term;
          if (filter) {
            [filterKey, term] = filterPhrases;
            queryParams[filterKey] = term;
          }
        });
      }
      const response =
        await communicationService.getPerformanceReport(queryParams);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...otherConfigs,
  });
  return query;
};
export const GET_JOB_REPORT = 'GET_JOB_REPORT';

const useGetJobReport = (config) => {
  const { params, filter = '', ...otherConfigs } = config;
  const query = useQuery({
    queryKey: [
      GET_JOB_REPORT,
      params?.limit,
      params?.offset,
      params?.job_id,
      params?.start_date,
      params?.end_date,
      params?.search,
      params?.order_by,
      params?.show_all,
      ...(filter ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        end_date: params?.end_date,
        start_date: params?.start_date,
        job_id: params?.job_id,
        show_all: params?.show_all,
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      if (filter?.length) {
        filter?.map((item) => {
          const filterPhrases = item?.split('=');
          let filterKey;
          let term;
          if (filter) {
            [filterKey, term] = filterPhrases;
            queryParams[filterKey] = term;
          }
        });
      }
      const response =
        await communicationService.getJobReport(queryParams);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...otherConfigs,
  });
  return query;
};

const useGetJobList = (config) => {
  const { params, ...otherConfigs } = config;
  const query = useQuery({
    queryKey: [params?.is_limited_search, params?.status],
    queryFn: async () => {
      const response = await communicationService.getJobList(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...otherConfigs,
  });
  return query;
};
const useDownloadJobReport = (config) => {
  const query = useMutation({
    mutationFn: async (body) => {
      const mutationResponse =
        await communicationService.downloadJobReport(body);
      return mutationResponse;
    },
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};
const useDownloadPerformanceReport = (config) => {
  const query = useMutation({
    mutationFn: async (body) => {
      const mutationResponse =
        await communicationService.downloadPerformanceReport(body);
      return mutationResponse;
    },
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};
const useSendPerformanceReportMail = (config) => {
  const query = useMutation({
    mutationFn: async (body) => {
      const mutationResponse =
        await communicationService.sendPerformanceReportMail(body);
      return mutationResponse;
    },
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};
const useSendJobReportEmail = (config) => {
  const query = useMutation({
    mutationFn: async (body) => {
      const mutationResponse =
        await communicationService.sendJobReportMail(body);
      return mutationResponse;
    },
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};
export {
  useGetRecruiters,
  useGetInterviewers,
  useGetOfferTransactionTypes,
  useGetCommunicationLogsList,
  useGetCommunicationLogsCards,
  useGetCommunicationTransactions,
  useGetCommunicationTransactionsTemplate,
  useGetPerformanceReport,
  useGetJobReport,
  useGetJobList,
  useDownloadJobReport,
  useDownloadPerformanceReport,
  useSendJobReportEmail,
  useSendPerformanceReportMail,
};
