import { filter, isEmpty } from 'lodash';

export const communication_types = [
  {
    text: 'Email',
    value: 'EMAIL',
  },
  {
    text: 'Voice Bot',
    value: 'VOICE_BOT',
  },
  {
    text: 'SMS',
    value: 'SMS',
  },
  {
    text: 'Whatsapp',
    value: 'WHATSAPP',
  },
  {
    text: 'Offer Letter',
    value: 'Offer_Letter',
  },
];

export const communication_status = [
  {
    text: 'Sent',
    text: 'Sent',
    value: 'SEND',
  },
  {
    text: 'Delivered',
    text: 'Delivered',
    value: 'DELIVERED',
  },

  {
    text: 'Failed',
    value: 'FAILED',
  },
  {
    text: 'BOUNCE',
    value: 'BOUNCE',
  },
];

export const communication_User_type = [
  {
    text: 'Candidate',
    value: 'CANDIDATE',
  },
  {
    text: 'Reviewer',
    value: 'REVIEWER',
  },
  {
    text: 'Recruiter',
    value: 'RECRUITER',
  },
  {
    text: 'Interviewer',
    value: 'INTERVIEWER',
  },
];

export const interview_level_type = [
  {
    text: 'Online Interview',
    value: 'LIVE',
  },
  {
    text: 'AI Video Interview',
    value: 'AI_VIDEO',
  },
  {
    text: 'Face-to-Face Interview',
    value: 'F2F',
  },
  {
    text: 'Online Test',
    value: 'AI_INTERVIEW_WITHOUT_VIDEO',
  },
  {
    text: 'Telephonic',
    value: 'TELEPHONIC',
  },
  {
    text: 'Big 5 -Traits - level 2',
    value: 'PERSONALITY',
  },
];
export const communicationReportTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Job Name',
    dataIndex: 'job',
    key: 'job',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Communication Type',
    dataIndex: 'communication_type',
    key: 'communication_type',
    is_sort: true,
    is_search: false,
    fieldShow: true,
    is_filter: true,
    dynamic_filter: false,
    filters: communication_types,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Recipient Name ',
    dataIndex: 'receiver_name',
    key: 'receiver',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Recipient Email ',
    dataIndex: 'receiver',
    key: 'receiver',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Mobile Number',
    dataIndex: 'receiver_phone',
    key: 'receiver_phone',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'User',
    dataIndex: 'user_type',
    key: 'user_type',
    is_sort: true,
    is_search: false,
    fieldShow: true,
    is_filter: true,
    dynamic_filter: false,
    filters: communication_User_type,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Interview Level',
    dataIndex: 'interview_level',
    key: 'interview_level',
    is_sort: true,
    is_search: false,
    fieldShow: true,
    is_filter: true,
    dynamic_filter: false,
    filters: interview_level_type,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    is_sort: true,
    is_search: false,
    fieldShow: true,
    is_filter: true,
    dynamic_filter: false,
    filters: communication_status,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created',
    dataIndex: 'send_date',
    key: 'send_date',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    colType: 'date',
    position: 0,
  },
];

export const sourcingReportTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Candidate Name',
    dataIndex: 'first_name',
    key: 'first_name',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone Number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Source',
    dataIndex: 'title',
    key: 'title',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created',
    dataIndex: 'sourced_date',
    key: 'sourced_date',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    colType: 'date',
    position: 0,
  },
];

export const screeningReportTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Candidate Name',
    dataIndex: 'first_name',
    key: 'first_name',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone Number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Screening',
    dataIndex: 'status_display',
    key: 'status_display',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Total Experience',
    dataIndex: 'total_experience',
    key: 'total_experience',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Current Company',
    dataIndex: 'current_company',
    key: 'current_company',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Current CTC',
    dataIndex: 'current_ctc',
    key: 'current_ctc',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Expected CTC',
    dataIndex: 'expected_ctc',
    key: 'expected_ctc',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Notice Period',
    dataIndex: 'notice_period',
    key: 'notice_period',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
];

export const JobReportTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Candidate Name',
    dataIndex: 'candidate_name',
    key: 'candidate_name',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Experience',
    dataIndex: 'total_experience',
    key: 'total_experience',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 1,
  },
  {
    fixed: '',
    width: 200,
    title: 'Current CTC',
    dataIndex: 'current_ctc',
    key: 'current_ctc',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 2,
  },
  {
    fixed: '',
    width: 250,
    title: 'Expected CTC',
    dataIndex: 'expected_ctc',
    key: 'expected_ctc',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 3,
  },
  // {
  //   fixed: '',
  //   width: 200,
  //   title: 'Location',
  //   dataIndex: 'sourfces',
  //   key: 'sources',
  //   is_sort: true,
  //   is_search: true,
  //   fieldShow: true,
  //   is_filter: false,
  //   dynamic_filter: false,
  //   filters: communication_User_type,
  //   isExpanded: false,
  //   position: 4,
  // },
  {
    fixed: '',
    width: 200,
    title: 'Notice Period',
    dataIndex: 'notice_period',
    key: 'notice_period',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    dynamic_filter: false,
    filters: communication_User_type,
    isExpanded: false,
    position: 4,
  },
  {
    fixed: '',
    width: 200,
    title: 'Last Activity',
    isGroup: true, // This column is a group
    fieldShow: true,
    children: [
      {
        title: 'Event',
        dataIndex: 'last_activity_interview_level',
        key: 'last_activity_interview_level',
        width: 100,
        is_sort: true,
        is_search: true,
        fieldShow: true,
        is_filter: false,
        isExpanded: false,
        position: 5,
      },
      {
        title: 'Date',
        dataIndex: 'last_activity_attended_date',
        key: 'last_activity_attended_date',
        width: 150,
        is_sort: true,
        is_search: true,
        fieldShow: true,
        is_filter: false,
        isExpanded: false,
        position: 5,
      },
      {
        title: 'Status',
        dataIndex: 'last_activity_status',
        key: 'last_activity_status',
        width: 150,
        is_sort: true,
        is_search: true,
        fieldShow: true,
        is_filter: false,
        isExpanded: false,
        position: 5,
      },
    ],
    position: 5,
  },
];
export const PerformanceReportTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Name',
    dataIndex: 'interviewer_name',
    key: 'interviewer_name',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },

  {
    fixed: '',
    width: 200,
    title: 'Interview Type',
    dataIndex: 'interview_types',
    key: 'interview_types',
    is_sort: true,
    is_search: false,
    fieldShow: true,
    is_filter: true,
    dynamic_filter: false,
    filters: interview_level_type,
    isExpanded: false,
    position: 1,
  },
  {
    fixed: '',
    width: 200,
    title: 'Total Interviews',
    dataIndex: 'total_interviews',
    key: 'total_interviews',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 2,
  },
  {
    fixed: '',
    width: 200,
    title: 'Success Interviews',
    dataIndex: 'passed_interviews',
    key: 'passed_interviews',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 3,
  },
  {
    fixed: '',
    width: 200,
    title: 'Failed Interviews',
    dataIndex: 'failed_interviews',
    key: 'failed_interviews',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 4,
  },
  {
    fixed: '',
    width: 200,
    title: 'No. of Reschedule ',
    dataIndex: 'rescheduled_interviews',
    key: 'rescheduled_interviews',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 5,
  },
  {
    fixed: '',
    width: 200,
    title: 'Interview Success Rate',
    dataIndex: 'success_rate',
    key: 'success_rate',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 6,
  },
];
export const RecruiterReportTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Recruiter',
    dataIndex: 'recruiter_name',
    key: 'recruiter_name',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 250,
    title: 'No. of Jobs Recruited',
    dataIndex: 'number_of_jobs_recruited',
    key: 'number_of_jobs_recruited',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 1,
  },
  // {
  //   fixed: '',
  //   width: 200,
  //   title: 'No. of Interviews ',
  //   dataIndex: 'Recruciter',
  //   key: 'Recruciter',
  //   is_sort: true,
  //   is_search: true,
  //   fieldShow: true,
  //   is_filter: false,
  //   isExpanded: false,
  //   position: 2,
  // },
  {
    fixed: '',
    width: 250,
    title: 'No.of Candidate Sourced',
    dataIndex: 'number_of_candidates_sourced',
    key: 'number_of_candidates_sourced',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 2,
  },
  {
    fixed: '',
    width: 200,
    title: 'Number of Candidate',
    isGroup: true, // This column is a group
    fieldShow: true,
    children: [
      // {
      //   title: 'Success',
      //   dataIndex: 'communication_type',
      //   key: 'communication_type',
      //   width: 100,
      //   is_sort: true,
      //   is_search: false,
      //   fieldShow: true,
      //   is_filter: true,
      //   dynamic_filter: false,
      //   filters: communication_types,
      //   isExpanded: false,
      //   position: 3,
      // },
      {
        title: 'Offer',
        dataIndex: 'offered_candidates',
        key: 'offered_candidates',
        width: 100,
        is_sort: true,
        is_search: true,
        fieldShow: true,
        is_filter: false,
        isExpanded: false,
        position: 3,
      },
      {
        title: 'Hire',
        dataIndex: 'hired_candidate',
        key: 'hired_candidate',
        width: 100,
        is_sort: true,
        is_search: true,
        fieldShow: true,
        is_filter: false,
        isExpanded: false,
        position: 4,
      },
    ],
    position: 3,
  },
  {
    fixed: '',
    width: 200,
    title: 'Source',
    dataIndex: 'sources',
    key: 'sources',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: true,
    dynamic_filter: false,
    filters: communication_User_type,
    isExpanded: false,
    position: 5,
  },
  {
    fixed: '',
    width: 200,
    title: 'Success Rate of Source Media',
    isGroup: true,
    fieldShow: true,
    children: [
      {
        title: 'Naukri',
        dataIndex: 'Naukri_percentage',
        key: 'Naukri_percentage',
        width: 100,
        is_sort: true,
        is_search: false,
        fieldShow: false,
        is_filter: false,
        dynamic_filter: false,
        isExpanded: false,
        position: 6,
      },
      {
        title: 'LinkedIn',
        dataIndex: 'LinkedIn_percentage',
        key: 'LinkedIn_percentage',
        width: 100,
        is_sort: true,
        is_search: true,
        fieldShow: true,
        is_filter: false,
        isExpanded: false,
        position: 6,
      },
      {
        title: 'Indeed',
        dataIndex: 'Indeed_percentage',
        key: 'Indeed_percentage',
        width: 100,
        is_sort: true,
        is_search: true,
        fieldShow: true,
        is_filter: false,
        isExpanded: false,
        position: 6,
      },
      {
        title: 'Monster',
        dataIndex: 'Monster_percentage',
        key: 'Monster_percentage',
        width: 100,
        is_sort: true,
        is_search: true,
        fieldShow: true,
        is_filter: false,
        isExpanded: false,
        position: 6,
      },
      {
        title: 'Consultancy',
        dataIndex: 'Consultancy_percentage',
        key: 'Consultancy_percentage',
        width: 130,
        is_sort: true,
        is_search: true,
        fieldShow: true,
        is_filter: false,
        isExpanded: false,
        position: 6,
      },
      {
        title: ' Excel Upload',
        dataIndex: 'Excel_Upload_percentage',
        key: 'Excel_Upload_percentage',
        width: 150,
        is_sort: true,
        is_search: true,
        fieldShow: true,
        is_filter: false,
        isExpanded: false,
        position: 6,
      },
    ],
    position: 6,
  },
];

// export const JobReportTableConfig = [
//   {
//     fixed: '',
//     width: 200,
//     title: 'Job Name',
//     dataIndex: 'job',
//     key: 'job',
//     is_sort: true,
//     is_search: true,
//     fieldShow: true,
//     is_filter: false,
//     isExpanded: false,
//     position: 0,
//   },
//   {
//     fixed: '',
//     width: 200,
//     title: 'Communication Type',
//     dataIndex: 'communication_type',
//     key: 'communication_type',
//     is_sort: true,
//     is_search: false,
//     fieldShow: true,
//     is_filter: true,
//     dynamic_filter: false,
//     filters: communication_types,
//     isExpanded: false,
//     position: 0,
//   },
//   {
//     fixed: '',
//     width: 200,
//     title: 'Recipient',
//     dataIndex: 'reciever',
//     key: 'reciever',
//     is_sort: true,
//     is_search: true,
//     fieldShow: true,
//     is_filter: false,
//     isExpanded: false,
//     position: 0,
//   },
//   {
//     fixed: '',
//     width: 200,
//     title: 'User',
//     dataIndex: 'user_type',
//     key: 'user_type',
//     is_sort: true,
//     is_search: false,
//     fieldShow: true,
//     is_filter: true,
//     dynamic_filter: false,
//     filters: communication_User_type,
//     isExpanded: false,
//     position: 0,
//   },
//   {
//     fixed: '',
//     width: 200,
//     title: 'Interview Level',
//     dataIndex: 'interview_level',
//     key: 'interview_level',
//     is_sort: true,
//     is_search: false,
//     fieldShow: true,
//     is_filter: true,
//     dynamic_filter: false,
//     filters: interview_level_type,
//     isExpanded: false,
//     position: 0,
//   },
//   {
//     fixed: '',
//     width: 200,
//     title: 'Status',
//     dataIndex: 'status',
//     key: 'status',
//     is_sort: true,
//     is_search: false,
//     fieldShow: true,
//     is_filter: true,
//     dynamic_filter: false,
//     filters: communication_status,
//     isExpanded: false,
//     position: 0,
//   },
//   {
//     fixed: '',
//     width: 200,
//     title: 'Created',
//     dataIndex: 'send_date',
//     key: 'send_date',
//     is_sort: true,
//     is_search: true,
//     fieldShow: true,
//     is_filter: false,
//     isExpanded: false,
//     colType: 'date',
//     position: 0,
//   },
// ];
export const EMAIL_COLORS = [
  {
    base: '#9471FF',
    legend: 'bg-[#9471FF]',
    text: 'text-[#9471FF]',
  },
  {
    base: '#819AFF',
    legend: 'bg-[#819AFF]',
    text: 'text-[#819AFF]',
  },
  {
    base: '#FDBA60',
    legend: 'bg-[#FDBA60]',
    text: 'text-[#FDBA60]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const WHATSAPP_COLORS = [
  {
    base: '#0492BE',
    legend: 'bg-[#0492BE]',
    text: 'text-[#0492BE]',
  },
  {
    base: '#07D4FD',
    legend: 'bg-[#07D4FD]',
    text: 'text-[#07D4FD]',
  },
  {
    base: '#58E0FA',
    legend: 'bg-[#58E0FA]',
    text: 'text-[#58E0FA]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const SMS_COLORS = [
  {
    base: '#43488A',
    legend: 'bg-[#43488A]',
    text: 'text-[#43488A]',
  },
  {
    base: '#5BA688',
    legend: 'bg-[#5BA688]',
    text: 'text-[#5BA688]',
  },
  {
    base: '#EABD24',
    legend: 'bg-[#EABD24]',
    text: 'text-[#EABD24]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
  {
    base: '#EABD24',
    legend: 'bg-[#EABD24]',
    text: 'text-[#EABD24]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const VOICE_BOT_COLORS = [
  {
    base: '#827799',
    legend: 'bg-[#827799]',
    text: 'text-[#827799]',
  },
  {
    base: '#47A5DB',
    legend: 'bg-[#47A5DB]',
    text: 'text-[#47A5DB]',
  },
  {
    base: '#49637A',
    legend: 'bg-[#49637A]',
    text: 'text-[#49637A]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const INTERVAL_TYPES = {
  MONTHLY: 'month',
  WEEKLY: 'week',
  CUSTOM: 'day', // Custom Ranges
};
export const Dot = ({ color }) => (
  <div className={`w-2 h-7 rounded-full ${color}`}></div>
);

export const Date = ({ start, end, intervalType, dateRange }) => {
  let date = start?.format('MMMM YYYY');
  if (intervalType === INTERVAL_TYPES.WEEKLY) {
    date = `${start?.format('DD MMMM YYYY')} - ${end?.format('DD MMMM YYYY')}`;
  } else if (
    intervalType === INTERVAL_TYPES.CUSTOM &&
    !isEmpty(dateRange)
  ) {
    const [rangeStart, rangeEnd] = dateRange;
    date = `${rangeStart?.format('DD MMMM YYYY')} - ${rangeEnd?.format('DD MMMM YYYY')}`;
  }
  return (
    <h3 className="text-[#4A4A4A] font-semibold text-base">{date}</h3>
  );
};
export const CustomTooltip = ({ active, payload }) => {
  if (active && !isEmpty(payload)) {
    const markupColor = `text-[${payload[0].payload.fill}]`;
    return (
      <div className=" backdrop-blur-3xl rounded-lg py-1 px-2 bg-white">
        <p
          className={`text-xs font-normal ${markupColor}`}
        >{`${payload[0].value} ${payload[0].name}`}</p>
      </div>
    );
  }
};
